import React from "react"
import SEO from "../components/Seo"
import Title from "../components/Title"
import { BiLinkExternal } from "react-icons/bi"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import { StaticImage } from "gatsby-plugin-image"

const Publication = () => {
  const getRefereedPublication = language => {
    let pVIS23WK = "VIS23WK"

    let pVIS23WKEn =
      "Echoes in the Gallery: A Collaborative Immersive Analytics System for Analyzing Audience Reactions in Virtual Reality Exhibitions"
    let pVIS23WKJp =
      "Echoes in the Gallery: A Collaborative Immersive Analytics System for Analyzing Audience Reactions in Virtual Reality Exhibitions"
    let pVIS23WKEnA =
      "Yuan, L., Tong, W., Takahira, K., Wen, Z., Yang, Y., and Qu, H."
    let pVIS23WKJpA =
      "Yuan, L., Tong, W., Takahira, K., Wen, Z., Yang, Y., and Qu, H."
    let pVIS23WKEnW =
      "MERCADO Workshop @ IEEE Visualization Conference (VIS), 2023"
    let pVIS23WKJpW =
      "MERCADO Workshop @ IEEE Visualization Conference (VIS), 2023"
    let pVIS23WKEnD =
      "We proposed a collaborative immersive analytics system for effectively analyzing the reactions of audiences in virtual reality exhibitions."
    let pVIS23WKJpD =
      "フリーフロート型カーシェアリングの需給不均衡問題を解決するための分散的制御手法を提案し、マルチエージェントシミュレーションにより効果を検証した。"

    let pAAAI2021 = "AAAI2021"

    let p1En =
      "Contract-based Inter-user Usage Coordination in Free-floating Car Sharing"
    let p1Jp =
      "Contract-based Inter-user Usage Coordination in Free-floating Car Sharing"
    let p1EnA = "Takahira, K., and Matsubara, S."
    let p1JpA = "Takahira, K., and Matsubara, S."
    let p1EnW =
      "Proceedings of the AAAI Conference on Artificial Intelligence. 2021"
    let p1JpW =
      "Proceedings of the AAAI Conference on Artificial Intelligence. 2021"
    let p1EnD =
      "A decentralized control method is proposed to solve the supply-demand imbalance problem in free-floating car sharing, and its effectiveness is verified by multi-agent simulation."
    let p1JpD =
      "フリーフロート型カーシェアリングの需給不均衡問題を解決するための分散的制御手法を提案し、マルチエージェントシミュレーションにより効果を検証した。"

    let p1A = "https://ojs.aaai.org/index.php/AAAI/article/view/17354"

    let pSpringer2021 = "Springer2021"

    let p2En =
      "Forecasting the movement of internally displaced people by artificial intelligence"
    let p2Jp =
      "Forecasting the movement of internally displaced people by artificial intelligence"
    let p2EnA =
      "Oishi, A., Teshima, T., Kojima, N., Kiriha, M., Kojima, N., Sasaki, T., Takahira, K., Takeuchi, T., Tajima, K., Noda, C., Hirose, H., and Yamanaka, S."
    let p2JpA =
      "Oishi, A., Teshima, T., Kojima, N., Kiriha, M., Kojima, N., Sasaki, T., Takahira, K., Takeuchi, T., Tajima, K., Noda, C., Hirose, H., and Yamanaka, S."

    let p2EnW =
      "Digital Innovations, Business and Society in Africa: New Frontiers and a Shared Strategic Vision (Springer)"
    let p2JpW =
      "Digital Innovations, Business and Society in Africa: New Frontiers and a Shared Strategic Vision (Springer)"
    let p2EnD =
      "My team investigated methods for predicting refugee routes and developed a prediction model in the field of Congolese people."
    let p2JpD =
      "難民問題に関する人工知能技術の応用の現状をサーベイの上、コンゴ民主共和国をフィールドに難民の移動予測モデルの提案とその評価を行った。"
    let p2A = "https://link.springer.com/chapter/10.1007/978-3-030-77987-0_14"

    if (language == "JP") {
      return [
        {
          t: pVIS23WKJp,
          a: pVIS23WKJpA,
          w: pVIS23WKJpW,
          d: pVIS23WKJpD,
          i: pVIS23WK,
        },

        { t: p1Jp, a: p1JpA, w: p1JpW, d: p1JpD, l: p1A, i: pAAAI2021 },
        { t: p2Jp, a: p2JpA, w: p2JpW, d: p2JpD, l: p2A, i: pSpringer2021 },
      ]
    } else {
      return [
        {
          t: pVIS23WKEn,
          a: pVIS23WKEnA,
          w: pVIS23WKEnW,
          d: pVIS23WKEnD,
          i: pVIS23WK,
        },
        { t: p1En, a: p1EnA, w: p1EnW, d: p1EnD, l: p1A, i: pAAAI2021 },
        { t: p2En, a: p2EnA, w: p2EnW, d: p2EnD, l: p2A, i: pSpringer2021 },
      ]
    }
  }
  const getPresentation = language => {
    let p1En =
      "Auction-based Distributed Regulation on Free-floating Car Sharing"
    let p1Jp =
      "フリーフロート型カーシェアリングにおけるオークションを用いた分散的制御手法"
    let p1EnA = "Kentaro Takahira"
    let p1JpA = "高比良健太郎"
    let p1EnW = "Poster Session @ IEEE Visualization Conference (VIS), 2023"
    let p1JpW = "Poster Session @ IEEE Visualization Conference (VIS), 2023"
    let p1EnD =
      "Short discussion at the workshop organized by Nishiyama Laboratory, Kyoto University."
    let p1JpD = "京都大学西山研究室主催のワークショップでのショートトーク。"

    let p2En = "Self-Organizing Regulation Method in Free-Floating Car-Sharing"
    let p2Jp =
      "フリーフロート型カーシェアリングにおけるオークションを用いた自己組織的制御方法"
    let p2EnA = "Kentaro Takahira"
    let p2JpA = "高比良健太郎"
    let p2EnW =
      "Joint Agent Workshop & Symposium 2018 (JAWS2018) IEEE Japan Chapter"
    let p2JpW = "合同エージェントワークショップ & シンポジウム2018(JAWS2018)"
    let p2EnD =
      "Short discussion abount self-organized regulation method in free-floating car sharing"
    let p2JpD = "JAWS2018においてポスターセッション。"

    let p3En = "Multi-agent System and Society"
    let p3Jp = "マルチエージェントシステムと社会"
    let p3EnA = "Kentaro Takahira"
    let p3JpA = "高比良健太郎"
    let p3EnW =
      "Workshop on Recent Developments in Economic Theory and Its Applications 2016 Kyoto University"
    let p3JpW =
      "Workshop on Recent Developments in Economic Theory and Its Applications 2016 Kyoto University"
    let p3EnD =
      "Short discussion at the workshop organized by Nishiyama Laboratory, Kyoto University."
    let p3JpD = "京都大学西山研究室主催のワークショップでのショートトーク。"

    if (language == "JP") {
      return [
        // { t: p1Jp, a: p1JpA, w: p1JpW, d: p1JpD },
        { t: p2Jp, a: p2JpA, w: p2JpW, d: p2JpD },
        { t: p3Jp, a: p3JpA, w: p3JpW, d: p3JpD },
      ]
    } else {
      return [
        // { t: p1En, a: p1EnA, w: p1EnW, d: p1EnD },
        { t: p2En, a: p2EnA, w: p2EnW, d: p2EnD },
        { t: p3En, a: p3EnA, w: p3EnW, d: p3EnD },
      ]
    }
  }
  const getNonRefereedPublication = language => {
    let pVIS23Po = "VIS23Po"
    let pVIS23PoEn =
      "Landslide visualization situated on tangible terrain models"
    let pVIS23PoJp =
      "Landslide visualization situated on tangible terrain models"
    let pVIS23PoEnA =
      "Li, H,. Takahira, K., Wong, KK., Yang, L., Tong, W., and Qu, H."
    let pVIS23PoJpA =
      "Li, H,. Takahira, K., Wong, KK., Yang, L., Tong, W., and Qu, H."
    let pVIS23PoEnW =
      "Poster Session @ IEEE Visualization Conference (VIS), 2023"
    let pVIS23PoJpW =
      "Poster Session @ IEEE Visualization Conference (VIS), 2023"
    let pVIS23PoEnD =
      "Using a tangible terrain model of Hong Kong, we proposed a system that effectively analyzes data related to landslides and precipitation for the general public."
    let pVIS23PoJpD =
      "観光分野におけるMAAS型サービスのデザインに関する調査および提言を行った。"
    let pVIS23PoL =
      "https://www.jstage.jst.go.jp/article/oukan/2018/0/2018_A-2-3/_article/-char/ja/"

    let pMAAS = "MAAS"
    let p1En = "Service Design of MaaS for Tourism"
    let p1Jp = "観光分野におけるMAAS型サービスデザイン"
    let p1EnA =
      "Hayakawa Y., Li J., Oku T., Takahira K., Li H., Kawamori R., Sato A.-H."
    let p1JpA = "早川慶朗, 奥拓郎, 高比良健太郎, 李慧勇, 河盛亮介, 佐藤彰洋."
    let p1EnW = "The Conference of Transdisciplinary Federation 2018"
    let p1JpW = "横幹連合コンファレンス予稿集 第9回横幹連合コンファレンス 2018"
    let p1EnD =
      "A survey and recommendations on MAAS-type service design in the field of tourism was presented."
    let p1JpD =
      "観光分野におけるMAAS型サービスのデザインに関する調査および提言を行った。"
    let p1L =
      "https://www.jstage.jst.go.jp/article/oukan/2018/0/2018_A-2-3/_article/-char/ja/"

    if (language == "JP") {
      return [
        {
          t: pVIS23PoJp,
          a: pVIS23PoJpA,
          w: pVIS23PoJpW,
          d: pVIS23PoJpD,
          l: pVIS23PoL,
          // i: pVIS23Po,
        },
        { t: p1Jp, a: p1JpA, w: p1JpW, d: p1JpD, l: p1L, i: pMAAS },
      ]
    } else {
      return [
        {
          t: pVIS23PoEn,
          a: pVIS23PoEnA,
          w: pVIS23PoEnW,
          d: pVIS23PoEnD,
          // l: pVIS23PoL,
          i: pVIS23Po,
        },
        { t: p1En, a: p1EnA, w: p1EnW, d: p1EnD, l: p1L, i: pMAAS },
      ]
    }
  }
  const publicationAll = {
    EN: {
      referred: getRefereedPublication("EN"),
      nonReferred: getNonRefereedPublication("EN"),
      presentation: getPresentation("EN"),
    },
    JP: {
      referred: getRefereedPublication("JP"),
      nonReferred: getNonRefereedPublication("JP"),
      presentation: getPresentation("JP"),
    },
  }
  function imageSwitch(param) {
    switch (param) {
      case "VIS23WK":
        return (
          <StaticImage
            src="../assets/images/publication_imgs/VIS23WK.png"
            alt="profile"
            className="image-publication"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        )
      case "AAAI2021":
        return (
          <StaticImage
            src="../assets/images/publication_imgs/AAAI2021.png"
            alt="profile"
            className="image-publication"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        )
      case "VIS23Po":
        return (
          <StaticImage
            src="../assets/images/publication_imgs/vis23poster.png"
            alt="profile"
            className="image-publication"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        )
      case "MAAS":
        return (
          <StaticImage
            src="../assets/images/publication_imgs/maas.png"
            alt="profile"
            className="image-publication"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        )
      default:
        return (
          <StaticImage
            src="../assets/images/publication_imgs/AI_refugee.png"
            alt="profile"
            className="image-publication"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        )
    }
  }

  return (
    <>
      <SEO title="Kentaro Takahira" />
      <ThemeContext.Consumer>
        {theme => (
          <main className="pages">
            <section className="section projects">
              <Title title="Publication" />

              <div className="section-center">
                <div className="publication-group">
                  <H3
                    className={`${
                      theme.language === "JP" ? "h3PubJP" : "h4=3Pub"
                    }`}
                  >
                    {theme.language == "JP"
                      ? "査読付き論文"
                      : "Refereed Publications"}
                  </H3>

                  {publicationAll[theme.language].referred.map((p, idx) => {
                    return (
                      <article className="publication-section">
                        {imageSwitch(p.i)}

                        <div className="publication-content">
                          <h4
                            className={`${
                              theme.language === "JP"
                                ? "pubTitle h4PubTitle" // いずれにせよ英語のため．
                                : "pubTitle h4PubTitle"
                            }`}
                          >
                            {p.t}
                            {p.l && (
                              <a href={p.l}>
                                <BiLinkExternal
                                  className="project-icon"
                                  id="publishment_link"
                                ></BiLinkExternal>
                              </a>
                            )}
                          </h4>

                          <h4
                            className={`author ${
                              theme.language === "JP" ? "h4PubJP" : "h4Pub"
                            }`}
                          >
                            {p.a}
                          </h4>
                          <h4
                            className={`author ${
                              theme.language === "JP" ? "h4PubJP" : "h4Pub"
                            }`}
                          >
                            {p.w}
                          </h4>

                          <P className="aboutP">{p.d}</P>
                        </div>
                      </article>
                    )
                  })}
                </div>
                <div className="publication-group">
                  <H3
                    className={`${
                      theme.language === "JP" ? "h3PubJP" : "h4=3Pub"
                    }`}
                  >
                    {theme.language == "JP" ? "査読なし論文" : "Short Paper"}
                  </H3>
                  {publicationAll[theme.language].nonReferred.map(p => {
                    return (
                      <article className="publication-section">
                        {imageSwitch(p.i)}
                        <div className="publication-content">
                          <h4
                            className={`${
                              theme.language === "JP"
                                ? "pubTitle h4PubTitleJP"
                                : "pubTitle h4PubTitle"
                            }`}
                          >
                            {p.t}
                            {p.l && (
                              <a href={p.l}>
                                <BiLinkExternal
                                  className="project-icon"
                                  id="publishment_link"
                                ></BiLinkExternal>
                              </a>
                            )}
                          </h4>
                          <h4
                            className={`author ${
                              theme.language === "JP" ? "h4PubJP" : "h4Pub"
                            }`}
                          >
                            {p.a}
                          </h4>
                          <h4
                            className={`author ${
                              theme.language === "JP" ? "h4PubJP" : "h4Pub"
                            }`}
                          >
                            {p.w}
                          </h4>
                          <P className="aboutP">{p.d}</P>
                        </div>
                      </article>
                    )
                  })}
                </div>
                <div className="publication-group">
                  <H3
                    className={`${
                      theme.language === "JP" ? "h3PubJP" : "h4=3Pub"
                    }`}
                  >
                    {theme.language == "JP"
                      ? "口頭発表およびシンポジウム"
                      : "Talk and Poster"}
                  </H3>
                  <div className="no-image-publication-section-group">
                    {publicationAll[theme.language].presentation.map(p => {
                      return (
                        <article>
                          <div>
                            <h4
                              className={`${
                                theme.language === "JP"
                                  ? "pubTitle h4PubTitleJP"
                                  : "pubTitle h4PubTitle"
                              }`}
                            >
                              {p.t}
                              {p.l && (
                                <a href={p.l}>
                                  <BiLinkExternal
                                    className="project-icon"
                                    id="publishment_link"
                                  ></BiLinkExternal>
                                </a>
                              )}
                            </h4>
                            <h4
                              className={`author ${
                                theme.language === "JP" ? "h4PubJP" : "h4Pub"
                              }`}
                            >
                              {p.a}
                            </h4>
                            <h4
                              className={`author ${
                                theme.language === "JP" ? "h4PubJP" : "h4Pub"
                              }`}
                            >
                              {p.w}
                            </h4>
                            <P className="aboutP">{p.d}</P>
                          </div>
                        </article>
                      )
                    })}
                  </div>
                </div>
              </div>
            </section>
          </main>
        )}
      </ThemeContext.Consumer>
    </>
  )
}

export default Publication

const H4Title = styled.h4`
  font-family: "bitter", sans-serif;
  &:before {
    content: "▶ ";
    font-size: small;
    vertical-align: middle;
  }
`
const H4TitleJP = styled.h4`
  font-family: "", sans-serif;
  font-weight: 400;
  /* font-size: small; */
  &:before {
    content: "▶ ";
    font-size: small;
    vertical-align: middle;
  }
`

const H4 = styled.h4`
  font-family: "bitter", sans-serif;
`

const H4jp = styled.h4`
  font-family: "Shippori Mincho", sans-serif;
  /* font-weight: bold; */
`

const P = styled.p`
  font-family: "Shippori Mincho", sans-serif;
`

const H3 = styled.h3`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: -1vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-primary-1);
  }
`
